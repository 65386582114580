import React, { useState, useEffect } from "react"
import styled from "styled-components"
import queryString from 'query-string'
import { isMobile } from "react-device-detect";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import TextField from '../components/fields/TextField';
import Button from "../components/buttons/Button"
import { postData } from "../services/server";

const UnsubscribePage = (props) => {
    const [loading, setLoading] = useState(false);
    const [unsubscribed, setUnsubscribed] = useState(false);

    useEffect(
        () => {
            const urlParams = queryString.parse(typeof window !== `undefined` ? window.location.search : "");
              
            if (urlParams.email)
                setEmail(urlParams.email);
        }, 
        []
      );

    const [email, setEmail] = useState("");
    
    function onUnsubscribe() {
        setLoading(true);

        postData("/api/users/unsubscribe", {
            email
        },
            function(response) {
                setUnsubscribed(true);                
            },
            function(error, status) {
                window.alert("An error occured while updating your email preferences.");
            },
            function() {
                setLoading(false);
            }
        );
    }

    return (
      <>
        <SEO page="unsubscribe" title="Unsubscribe" />

        <div align="center">
            <h2>
                Unsubscribe
            </h2>

            <GoogleImage src="monkey-leaning.png" required lazy alt="Sign Monkey" />

            <div style={{maxWidth: "400px"}}>
            {unsubscribed ? 
                <div style={{color: "#155724", backgroundColor: "#d4edda", padding: "8px", borderRadius: "4px"}}>
                    <strong>You've Been Unsubscribed.</strong>
                    <p>
                        You'll no longer receive emails in your inbox from us, but you can change your decision at any time
                        on your <Link to="/account/">account page</Link>.
                    </p>
                </div>
            :
                <TextField
                    id="email"
                    name="email"
                    type="email"
                    label="Email Address"
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value) 
                    }}
                    onKeyDown={(e) => {
                        if (e.key == "Enter")
                            onUnsubscribe(e);
                    }}
                    disabled={loading}
                    wide
                    autoComplete="email"
                >
                    <Button 
                        icon={faEnvelope}
                        label="Unsubscribe"
                        color="blue"
                        busy={loading}
                        disabled={email.length === 0 || loading}
                        onClick={onUnsubscribe}
                    />
                </TextField>
            }
            </div>
        </div>
    </>
    )
}

export default UnsubscribePage;